function LeadSendTo({
  lang,
  users,
  gates,
  selected,
  selectedValue,
  setLeadTargetValue,
}) {
  const [searchTextLabel, setSearchTextLabel] = React.useState("");

  const filteredGates =
    gates &&
    gates
      .filter((gate) => {
        return (
          gate.gate_name
            .toLowerCase()
            .includes(searchTextLabel.toLowerCase()) ||
          gate.gate_id.toString().includes(searchTextLabel)
        );
      })
      .sort((a, b) => a.gate_name.localeCompare(b.gate_name));

  const filteredUsers =
    users &&
    users.filter((user) => {
      return (
        user.display_name
          .toLowerCase()
          .includes(searchTextLabel.toLowerCase()) ||
        user.username.toLowerCase().includes(searchTextLabel.toLowerCase())
      );
    });

  return (
    <>
      {selectedValue ? (
        <div class="sp-dropdown dropup sp-col">
          <div
            class="dropdown-button dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <div class="text" style={{ marginLeft: 10 }}>
              <div class="sp-text -ellipsis">
                {selected && selectedValue && selected === "gate"
                  ? selectedValue.gate_name
                  : selectedValue.display_name}
              </div>
              <div class="sp-text-help" style={{ fontSize: "smaller" }}>
                {selected && selectedValue && selected === "gate"
                  ? selectedValue.gate_id
                  : selectedValue.username}
              </div>
            </div>
            <i class="icon icon-angle-up"></i>
          </div>
          <div class="dropdown-menu sp-list" style={{ minHeight: 300 }}>
            <div class="sp-search" style={{ top: 0, position: "sticky", zIndex: 20, backgroundColor: "white" }}>
              <i class="icon icon-search" style={{ marginLeft: 10 }}></i>
              <input
                type="text"
                class="sp-input -borderless"
                placeholder={lang.__search}
                value={searchTextLabel}
                onChange={(e) => setSearchTextLabel(e.target.value)}
              />
            </div>
            {selected && selected === "gate"
              ? filteredGates.map((gateItem) => {
                  return (
                    <div
                      class="item -clickable sp-row -gap"
                      style={{
                        backgroundColor:
                          gateItem.gate_id === selectedValue.gate_id
                            ? "#6DA7FD"
                            : "",
                        color:
                          gateItem.gate_id === selectedValue.gate_id
                            ? "white"
                            : "",
                      }}
                      onClick={() => setLeadTargetValue(gateItem)}
                      key={gateItem.gate_id}
                    >
                      <div class="sp-col" style={{ marginLeft: 24 }}>
                        <div class="sp-text">{gateItem.gate_name}</div>
                        <div class="sp-text-help">{gateItem.gate_id}</div>
                      </div>
                    </div>
                  );
                })
              : filteredUsers.map((userItem) => {
                  return (
                    <div
                      class="item -clickable sp-row -gap"
                      style={{
                        backgroundColor:
                          userItem.username === selectedValue.username
                            ? "#6DA7FD"
                            : "",
                        color:
                          userItem.username === selectedValue.username
                            ? "white"
                            : "",
                      }}
                      onClick={() => setLeadTargetValue(userItem)}
                      key={userItem.username}
                    >
                      <div class="sp-col" style={{ marginLeft: 24 }}>
                        <div class="sp-text">{userItem.display_name}</div>
                        <div class="sp-text-help">{userItem.username}</div>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
